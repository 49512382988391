function hasInherit(property) {
  for (var i = property.value.length - 1; i >= 0; i--) {
    if (property.value[i][0] == 'inherit')
      return true;
  }

  return false;
}

module.exports = hasInherit;
