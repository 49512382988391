var riot = require('riot');
module.exports = 
riot.tag2('nav', '<div class="ui borderless main menu"> <div class="ui container"> <div class="ui fluid secondary menu"> <div class="horizontally fitted item" onclick="document.location=\'/#/accounts\';" style="cursor: pointer;"><img src="/img/mark.svg"></div> <div class="right menu"> <div class="vertically fitted item"> <div class="ui search content" if="{content}"> <div class="ui left icon input"> <input class="prompt" type="text" style="border:none; background: #f3f3f3;" placeholder="Search tags, titles..."><i class="search icon"> </i> </div> </div> </div> <div class="item" if="{account_id != \'domain7\'}"> <div class="ui button icon" onclick="{publish}"><i class="icon upload"></i></div> </div> <div class="horizontally fitted item ui dropdown" style="background:none!important;"> <div class="ui avatar image"><img class="ui image avatar" riot-src="{user &amp;&amp; user.photoURL ? user.photoURL : \'//placehold.it/128x128\'}"></div><i class="angle down icon"> </i> <div class="menu"><a class="item" href="/#/profile">My Profile</a><a class="item" onclick="{signout}">Sign Out</a></div> </div> </div> </div> </div> </div>', '', '', function(opts) {
const { user, content, account_id, firebase } = opts;
Object.assign(this, { account_id, user });

window.nav = this;

this.on('mount', () => {
  $('.ui.dropdown').dropdown();

  if (content) $('.ui.search').search({
    apiSettings: {
      responseAsync: content.getSearchDropdownResults
    }
  });
});

this.on('updated', () => {
  $('.ui.dropdown').dropdown();
});

this.publish = () => {
  content.deploy(`${account_id}.com`);
};

this.signout = () => {
  firebase.auth().signOut();
};
});