const path = require('path')
const fs = require('fs')
/**
 * Synchronously and recursively remove files and/or directories.
 * 
 * @param {String|Array<String>} paths Path or paths to remove.
 * @param {Object} [options] Options:
 * @param {Boolean} [options.verbose=false] Log all errors and print each path
 *  just before it's removed.
 * @param {Boolean} [options.ignoreErrors=false] If false, halt as soon as
 *  possible after an error occurs and invoke the callback. This implies an error
 *  removing the first of several paths would halt before touching the rest. If
 *  set, `ignoreErrors` overrides `ignoreMissing`.
 * @param {Boolean} [options.ignoreMissing=false] Whether to treat missing
 *  paths as errors.
 * @returns {void}
 */
const removeSync = (function(){
  function removeSync(paths, options){
    var verbose, ignoreErrors, ignoreMissing, p, stats, __ref, __i, __len;
    paths == null && (paths = []);
    options == null && (options = {});
    if (typeof paths === 'string') {
      paths = [paths];
    }
    options = (__ref = __import({
      verbose: false,
      ignoreErrors: false,
      ignoreMissing: false
    }, options), verbose = __ref.verbose, ignoreErrors = __ref.ignoreErrors, ignoreMissing = __ref.ignoreMissing, __ref);
    for (__i = 0, __len = paths.length; __i < __len; ++__i) {
      p = paths[__i];
      if (verbose) {
        console.log("rm -rv " + p);
      }
      try {
        stats = fs.lstatSync(p);
        if (stats.isSymbolicLink() || !stats.isDirectory()) {
          fs.unlinkSync(p);
        } else {
          fs.readdirSync(p).forEach(__fn);
          fs.rmdirSync(p);
        }
      } catch (err) {
        if (verbose) {
          console.error(err);
        }
        if (ignoreErrors || (ignoreMissing && err.code === 'ENOENT')) {
          continue;
        } else {
          throw err;
        }
      }
    }
    function __fn(it){
      return removeSync(path.join(p, it), options);
    }
  }
  return removeSync;
}());
function __import(obj, src){
  var own = {}.hasOwnProperty;
  for (var key in src) if (own.call(src, key)) obj[key] = src[key];
  return obj;
}

module.exports = removeSync