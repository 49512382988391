var fs = require('fs')
  , p = require('path')
  , metaForFiles = require('./meta-for-files')
  , readdirRecursive = require('./readdir-recursive')
;
  
module.exports = function(opts) {
  
    
  var {path, recursive} = opts
  
  //console.log(`Starting meta scan for ${path}`)
  if (recursive) { 
    const pathName = (path.indexOf('/') == 0 ? `/${path}` : path)
    return metaForFiles(readdirRecursive( pathName ? pathName : '/' ))
  } else { 
    const slashedPath = path ? path.replace(/^\//,'').replace(/\/$/,'') + '/' : ''
    
    // build list of dir files + _settings.json's for subfolders
    var dirList = []
    
    fs.readdirSync(`/${slashedPath}`).forEach((entry) => { 
      if (!entry.match(/^\./)) {
        if (p.extname(entry)) dirList.push(`${slashedPath}${entry}`)
        else dirList.push(`${slashedPath}${entry}/_settings.json`)
      }
    })
    
    return metaForFiles(dirList)
  }
  
}