module.exports={
	"tags": [],
	"type": "array",
	"items": {
		"type": "string"
	},
	"title": "Tags",
	"default": [
		"Draft"
	],
	"description": "Special Tags: Archived, Draft",
	"uniqueItems": true
}