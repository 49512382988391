module.exports={
    "assert": true,
    "buffer_ieee754": "< 0.9.7",
    "buffer": true,
    "child_process": true,
    "cluster": true,
    "console": true,
    "constants": true,
    "crypto": true,
    "_debugger": "< 8",
    "dgram": true,
    "dns": true,
    "domain": true,
    "events": true,
    "freelist": "< 6",
    "fs": true,
    "http": true,
    "http2": ">= 8.8",
    "https": true,
    "_http_server": ">= 0.11",
    "_linklist": "< 8",
    "module": true,
    "net": true,
    "os": true,
    "path": true,
    "perf_hooks": ">= 8.5",
    "process": ">= 1",
    "punycode": true,
    "querystring": true,
    "readline": true,
    "repl": true,
    "stream": true,
    "string_decoder": true,
    "sys": true,
    "timers": true,
    "tls": true,
    "tty": true,
    "url": true,
    "util": true,
    "v8": ">= 1",
    "vm": true,
    "zlib": true
}
