import api from '../authenticated-request'

export default async (opts = {})  => {
  const {account_id, firebase, targets, taskId} = opts
  
  const user = firebase.auth().currentUser 
  if (!targets || !targets.length) return Promise.resolve()
  
  else return api({method: 'POST', url: `/commit/${account_id}`, firebase: firebase}, {
    actor: {
      "name": user.displayName,
      "email": user.email
    },
    targets: targets,
    taskId: taskId
  })

}

