var riot = require('riot');
module.exports = 
riot.tag2('thumbnail', '<a class="document card ui transition fade in" href="{getLinkTo(item)}" onclick="{click_handler}"> <div class="ui borderless thumbnail menu {`full-menu` : hasThumbnail}" if="{showMenu}"> <div class="left menu" if="{hasThumbnail || isImage}"> <div class="item item-title"><span data-is="thumbnail-title" item="{item}" utils="{utils}"></span></div> </div> <div class="right menu"> <div class="item horizontally fitted ui dropdown" onclick="return false" style="padding: 0.5rem 0.5rem 0.5rem 0.75rem;"><i class="icon angle down"></i> <div class="menu"> <div class="item" onclick="{remove}"><i class="icon trash"></i>Delete</div> <div class="item" onclick="{clone}"><i class="icon copy"></i>Create a Copy</div> </div> </div> </div> </div> <div class="image" if="{isImage}"><img riot-src="{item.src}"></div> <div class="{hasThumbnail ? `image` : `content long-title`}" if="{!isImage}"> <div class="thumb-container {schema &amp;&amp; schema.$thumb &amp;&amp; schema.$thumb.large ? \'large\' : \'\'}" if="{hasThumbnail}"> <div class="overlay"></div> <iframe class="scaled" riot-src="{hasThumbnail}"></iframe> </div> <virtual if="{!hasThumbnail &amp;&amp; !isImage &amp;&amp; (item.title || isDevMode || isView || isSchema)}"> <div class="slight-title-color"> <div class="filename" if="{isDevMode || isView || isSchema || isFolder}"><i class="icon {isFolder ? \'folder\' : \'file\'}"></i>{item._basename}</div> <virtual if="{!isDevMode &amp;&amp; !isFolder  &amp;&amp; !isSchema}"> {item.title}</virtual> </div> </virtual> </div> <div class="extra content tags" if="{showTags &amp;&amp; item &amp;&amp; item.tags &amp;&amp; Array.isArray(item.tags)}"> <i class="icon tag"></i> <virtual if="{item.tags.length}">{item.tags.join(\', \')}</virtual> <virtual if="{!item.tags.length}"> <div class="untagged">( no tags )</div> </virtual> </div></a>', '', '', function(opts) {
var _this = this;

const { item, content, dev_mode, account_id, schema, utils, no_link, click_handler } = opts;
Object.assign(this, { utils, schema, click_handler });

if (item && !this.schema) this.schema = content.resolveSchema(item._id);

this.isDevMode = dev_mode ? dev_mode : false;
this.showMenu = opts.hide_menu ? false : true;
this.showTags = opts.hide_tags ? false : true;
this.isImage = content.isImage(this.item._id) && this.item.src;
this.isView = content.isView(item._id);
this.isSchema = content.isSchema(item._id);
this.isFolder = content.isFolder(item._id);

this.getLinkTo = item => {
  if (no_link) return '';else return `/#/${account_id}/${item._id}`;
};

this.remove = this.parent.remove;
this.clone = this.parent.clone;

this.iframePreviewPath = item => {
  if (item._id.match(/\.json$/)) {
    const htmlPath = `${_this.dataPath ? `/${_this.dataPath}` : ''}/${item._id.replace(/\.json$/, '.html')}`;
    if (content.exists(htmlPath)) return `${content.hostname}${htmlPath}`;
  }
};
this.hasThumbnail = this.iframePreviewPath(this.item);
});