function removeUnused(properties) {
  for (var i = properties.length - 1; i >= 0; i--) {
    var property = properties[i];

    if (property.unused)
      property.all.splice(property.position, 1);
  }
}

module.exports = removeUnused;
