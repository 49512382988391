var riot = require('riot');
module.exports = 
riot.tag2('content-picker', '<div class="ui basic segment" style="margin-top:0;"> <div class="ui menu"> <div class="borderless item" style="cursor:pointer;"><i class="icon home" onclick="{crumbNav}" data-link=""></i><i class="icon angle right" if="{crumbs &amp;&amp; crumbs.length}"> </i><span if="{crumbs &amp;&amp; crumbs.length}"> <span each="{crumb, idx in crumbs}"><a onclick="{crumbNav}" data-link="{crumb.link}">{utils.humanTitle(crumb.text)} <span if="{(idx &lt; (crumbs.length -1))}"><i class="icon angle right"></i></span></a></span></span></div> <div class="right menu"> <div class="link borderless item" onclick="{close}"><i class="icon close"></i> <div data-tooltip="Return to content list" data-position="bottom right"><span>Cancel</span></div> </div> </div> </div> <div class="ui basic horizontal segment"> <div class="ui vertical segment"> <div class="ui mini button right floated" onclick="{blocks}" if="{content.isFolder(\'_views/blocks\')}">Blocks</div>Navigate folders and click an item to add it </div> <div class="ui basic vertical segment"> <div class="ui two cards"> <virtual data-is="thumbnail" hide_menu="{true}" hide_tags="{true}" schema="{schema}" no_link="{true}" account_id="{account_id}" content="{content}" utils="{utils}" item="{item}" each="{item in contents}" if="{includeInPicker(item)}" click_handler="{navigate}"></virtual> </div> </div> </div> </div>', '', '', function(opts) {
var _this = this;

window.contentpicker = this;
const { content, account_id, utils, _, riot, models, noty, user } = opts;
Object.assign(this, { content, account_id, utils, _, user });

this.defaultPath = () => {
  if (content.exists('documents/page-templates')) return 'documents/page-templates';
  if (content.exists('_views/blocks')) return '_views/blocks';
  return '_views';
};

if (!this.url) this.url = this.defaultPath();

this.on('mount', () => {
  _this.update();
});

this.on('update', () => {
  var link = '';
  var crumbs = [];
  var p = _this.url ? _this.url : '/';
  _this.contents = content.get(p);

  _this.url.split('/').forEach(function (crumb) {
    link += '/' + crumb;
    crumbs.push({
      link: link,
      text: crumb
    });
  });
  _this.crumbs = crumbs;
});

this.setUrl = url => {
  _this.url = url;
  _this.schema = content.resolveSchema(url);
  _this.update();
};

this.crumbNav = ev => {
  var el = ev.target;
  _this.setUrl($(el).data('link').replace(/^\//, ''));
};

this.includeInPicker = item => {
  return !content.isHidden(item._id) && (content.isView(item._id) || content.isFolder(item._id) || content.isJson(item._id) && Array.isArray(item.blocks) && item.blocks.length);
};

this.home = () => {
  _this.setUrl('');
};

this.close = function () {
  $('.content-picker.active').removeClass('active');$('.section-list').show();
};

this.blocks = () => {
  _this.setUrl("_views/blocks");
};

this.navigate = ev => {
  console.log('navigate', ev);

  var obj = ev.item.item;

  if (content.isFolder(obj._id)) _this.setUrl(obj._id);else {
    _this.addToDoc(obj);
    _this.close();
    _this.parent.update();
  }
};

this.addToDoc = item => {
  var doc = _this.parent.parent.data;
  var currentBlock = doc.blocks.length;

  if (content.isView(item._id)) {

    // for adding pug templates
    doc.blocks.push({
      displayOrder: currentBlock,
      filename: item._id
    });

    _this.parent.parent.update();
  } else if (item.blocks && item.blocks.length) {

    _.each(_.cloneDeep(item.blocks), function (blk) {
      blk.displayOrder = currentBlock;
      doc.blocks.push(blk);
      currentBlock += 1;
    });

    _this.parent.parent.update();
  }
};
});