var riot = require('riot');
module.exports = 
riot.tag2('jsoneditor', '<virtual if="{settings &amp;&amp; settings.modal}"> <i class="close icon"></i> <div class="header" if="{settings.modal.title}">{settings.modal.title}</div> <div class="content {\'no-title\' : !settings || !settings.modal || !settings.modal.title}"> <p if="{settings.modal.description}"> {settings.modal.description}</p> <div class="ui basic segment"> <div class="editor-holder" id="modal_editor"></div> </div> </div> <div class="actions"> <div class="ui button deny" if="{!settings.noCancel}">{settings.modal.denyText ? settings.modal.denyText : \'Cancel\'}</div> <div class="ui primary approve submit button">{settings.modal.approveText ? settings.modal.approveText : \'Save\'}</div> </div> </virtual>', '', '', function(opts) {
var _this = this;

const { content, account_id, utils, _, riot, models, noty, user } = opts;
var tag = window.jsoneditorModal = this;
const deepmerge = require('deepmerge');

this.on('mount', () => {
  if (opts.settings) {
    _this.settings = opts.settings;
    _this.update();
  }
});

this.on('updated', () => {

  if (_this.settings && _this.settings.data) {
    $('#modal_editor').html("");

    _this.editor = content.initJsonEditor({
      account_id: account_id,
      doc: content.filterDocForEditor(_this.settings.data),
      element: 'modal_editor',
      schema: _this.settings.schema
    }, () => {
      // do something here when editor content changes
      Object.assign(_this.settings.data, _this.editor.getValue());
    });

    $('#jsoneditor-modal').modal({
      observeChanges: true,
      onHidden: el => {
        // fixes bug where modals don't close
        $(".ui.dimmer.modals.page.transition.visible.active").removeClass('visible active').css('display', '');
      },
      onApprove: () => {

        if (!_this.editor || !_this.editor.validate()) return false; // form is invalid
        else {
            // form valid, proceed to callback or by default, return true
            if (typeof tag.settings.modal.onApprove == "function") {
              return tag.settings.modal.onApprove(_this.editor.getValue());
            } else return true;
          }
      },
      onDeny: tag.settings.modal.onDeny ? tag.settings.modal.onDeny : () => {
        return true;
      }

    }).modal('show');
  }
});
});