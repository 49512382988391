import _ from 'lodash'
import path from 'path'
import fs from 'fs'

function filterObj(obj, props) {
  var out = {}
  props.forEach((p) => out[p] = obj.p)
  return out
}

const isSchema = (p) => {
  return p && p.match('^_schemas|\.schema.json$|\.schema$')
}

export default (dataPath, opts = {}) => {
  if (!dataPath) dataPath = '/'
  const {showHidden, only} = opts
  const utils = require('../../../shared/utils')
  var dirContents    
  try {
    dirContents = fs.readdirSync(dataPath)    
  } catch (err) {
    console.log(err)
    dirContents = []
  }
  
  var folder = {}
  if (Array.isArray(dirContents)) dirContents.forEach((file) => {
    var fileContents, out;
    if (file.match(/^\_.*?$/) && !showHidden) return // skip _ hidden files unless showHidden is passed in opts
    if (file.match(/^\./)) return // always skip actual unix . hidden files
    
    const filename = `${dataPath}/${file}`
    
    // json files
    if (file.match(/\.json$/,'')) {
      
      fileContents = utils.loadDataFromFile(filename)
      if (fileContents && (!fileContents.title)) fileContents.title = utils.humanTitle(file)
      if (isSchema(filename)) fileContents.title = file
        
      if (!only || !Array.isArray(only) || !only.length) out = fileContents
      else out = filterObj(fileContents, only)
      const outPath = (file == '_settings.json') ? file.replace(/\.json$/,'') : file
      _.set(folder, utils.pathToAddress(outPath), out)
    
    // any other files
    } else {
      
      fileContents = { title: file, type: 'file', ext: path.extname(file) }
      
      if (content.isView(file)) fileContents.type = 'view'
      else try { fileContents.src = fs.readFileSync(filename).toString() } catch (err) {}      
      //console.log('fileContents', fileContents)
        
      // detect if it's a folder
      const folderSettingsPath = `${filename}/_settings.json`
      
      if (fs.existsSync(folderSettingsPath)) { 
        fileContents = utils.loadDataFromFile(folderSettingsPath)
        if (fileContents && !fileContents.title) fileContents.title = utils.humanTitle(file)
        out = fileContents
        out['type'] = 'folder'
      }
        
      _.set(folder, utils.pathToAddress(file), fileContents)
    }
  })
  //console.log("getFolder",dataPath, folder)
  
  return folder
}