require("json-editor"); 
require("./json-editor/themes/semantic_ui"); 
const MediumEditor = require("medium-editor"); 
//require("./json-editor/editors/array/selectize"); 
window.MediumEditor = MediumEditor;

!function(t){"use strict";var e=t.HTMLCanvasElement&&t.HTMLCanvasElement.prototype,o=t.Blob&&function(){try{return Boolean(new Blob)}catch(t){return!1}}(),n=o&&t.Uint8Array&&function(){try{return 100===new Blob([new Uint8Array(100)]).size}catch(t){return!1}}(),r=t.BlobBuilder||t.WebKitBlobBuilder||t.MozBlobBuilder||t.MSBlobBuilder,a=/^data:((.*?)(;charset=.*?)?)(;base64)?,/,i=(o||r)&&t.atob&&t.ArrayBuffer&&t.Uint8Array&&function(t){var e,i,l,u,b,c,d,B,f;if(e=t.match(a),!e)throw new Error("invalid data URI");for(i=e[2]?e[1]:"text/plain"+(e[3]||";charset=US-ASCII"),l=!!e[4],u=t.slice(e[0].length),b=l?atob(u):decodeURIComponent(u),c=new ArrayBuffer(b.length),d=new Uint8Array(c),B=0;B<b.length;B+=1)d[B]=b.charCodeAt(B);return o?new Blob([n?d:c],{type:i}):(f=new r,f.append(c),f.getBlob(i))};t.HTMLCanvasElement&&!e.toBlob&&(e.mozGetAsFile?e.toBlob=function(t,o,n){t(n&&e.toDataURL&&i?i(this.toDataURL(o,n)):this.mozGetAsFile("blob",o))}:e.toDataURL&&i&&(e.toBlob=function(t,e,o){t(i(this.toDataURL(e,o)))})),"function"==typeof define&&define.amd?define(function(){return i}):"object"==typeof module&&module.exports?module.exports=i:t.dataURLtoBlob=i}(window);
Promise = require('es6-promise').Promise;
jsPDF = require('jspdf');

// image resizing
import loadImage from 'blueimp-load-image/js/load-image';
window.loadImage = loadImage;

require('blueimp-load-image/js/load-image-exif');
require('blueimp-load-image/js/load-image-meta');
require('blueimp-load-image/js/load-image-orientation');

// Add a resolver function to the beginning of the resolver list
// This will make it run before any other ones
JSONEditor.defaults.resolvers.unshift(function(schema) {
  if(schema.type === "string" && schema.format === "html") {
    return "mediumEditor";
  }

  // If no valid editor is returned, the next resolver function will be used
});

JSONEditor.defaults.editors.mediumEditor = JSONEditor.defaults.editors.string.extend({
  afterInputReady: function() {
    var self = this, options;
    $(self.input).removeClass('ui input');
    
    self.mediumEditor = new MediumEditor($(self.input)[0], {
        autoLink: true,    
        toolbar: {
            //static: true,
            //sticky: true,
            diffLeft: 0,
            diffTop: -10,
            buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'orderedlist', 'unorderedlist', 'quote'],
          
            /*
            //These are the default options for the toolbar if nothing is passed this is what is used
            allowMultiParagraphSelection: true,
            firstButtonClass: 'medium-editor-button-first',
            lastButtonClass: 'medium-editor-button-last',
            standardizeSelectionStart: false,
            static: true,
            relativeContainer: null,
            //options which only apply when static is true
            align: 'center',
            sticky: true,
            updateOnEmptySelection: false,
            */
        }
      
    });
    
    self.mediumEditor.updateData = function() {
      // Get editor's value
      // Set the value and update
      self.input.value = self.mediumEditor.getContent();
      self.value = self.input.value;
      self.is_dirty = true;
      self.onChange(true);
    }
    
    self.mediumEditor.subscribe('blur', function (event, editable) {
      self.mediumEditor.updateData();
    });
    
    $('body').on('mouseout', '[data-medium-focused]', function(ev) {
      var el = $(ev.target).closest('[data-medium-editor-editor-index]')[0];
      var ed = MediumEditor.getEditorFromElement(el)
      ed.updateData();
    });
    
    
    
  },
  destroy: function() {
    var self = this, options;    
    if (self.mediumEditor) self.mediumEditor.destroy()
  }
});
