export default async(opts = {}) => {
  
  const { user, db } = opts
  if (!user) return false

  var updates = {};
  var displayName = user.displayName || user.email;
  updates['users-profiles/' + user.uid + "/displayName"] = displayName;
  updates['users-profiles/' + user.uid + "/email"] = user.email;
  updates['users-profiles/' + user.uid + "/photoURL"] = user.photoURL;

  return db.ref().update(updates)

}
