import commit from './commit'
import utils from '../../utils'
import path from 'path'
import pathWithoutExtension from './path-without-extension'
import _ from 'lodash'

export default async (opts = {}) => {
  const {account_id, firebase, targetAddress, schema, uploadBlobs, Content} = opts
  var {data} = opts

  var targets = []
  var files = []
  var fileSuccessMap = {}

  var targetUrl = targetAddress;

  if (targetAddress == 'new' || targetAddress.match(/\/new$/)) {
    var title = data && data.title ? data.title : ''

    if (!title) title = prompt("Please enter a file name to create this document.");

    if (!title) return Promise.reject('Title required to save');
    else {
    
      targetUrl = targetAddress.replace(/new$/,'') + utils.slugify(title)
      .replace(/\-pug\-schema\-json$/,".pug.schema.json")
      .replace(/\-json$/,".json")
      .replace(/\-pug$/,".pug")
      .replace(/\-jade$/,".jade")
  
      if (!path.extname(targetUrl)) targetUrl = `${targetUrl}.json`
      
    }

  }

  const key = firebase.database().ref(`accounts-writes/${account_id}`).push().key;
  const storageRef = firebase.storage().ref(`accounts-writes/${account_id}/${key}`);

  if (uploadBlobs && Object.keys(uploadBlobs).length ) Object.keys(uploadBlobs).forEach((schemaPath) => {
    console.log('found upload for', schemaPath, uploadBlobs[schemaPath].name);
    addFile(uploadBlobs[schemaPath].data, uploadBlobs[schemaPath].name, schemaPath);
  })


  return Promise.all(
    files.map((file) => {
      return storageRef.child(file.name).put(file.payload)
      .then(() => _.set(data, fileSuccessMap[file.name], file.name) )
    })
  )
  .then( () => {
  
    targets.push({
      address: targetUrl,
      action: "write",
      payload: data
    })
  
  
    console.log('Generating?', schema.$generate, targetUrl, data)
  
    if (schema.$generate) targets.push({
      address: `${pathWithoutExtension(targetUrl)}.html`,
      action: "write",
      payload: Content.generate(targetUrl, {data: data, readOnly: true})
    })
  
  })
  .then( () => commit({targets: targets, taskId: key, firebase: firebase, account_id: account_id}) )
  .then( () => targetUrl )


  function addFile(file, name, schemaPath)  {
    if (file && name && schemaPath) {

      name = `_assets/${pathWithoutExtension(targetUrl)}/${name}`;        
      fileSuccessMap[name] = schemaPath
    
      files.push({
        name: name,
        payload: file
      });

      targets.push({
        address: name,
        action: "write",
        payload: "storage",
        taskId: key
      });      
    }

  }


}

