module.exports={
  "$schema": "http://json-schema.org/draft-04/schema#",
  "type": "object",
  "title": "Account",
  "properties": {
    "title": {      
      "title": " ",
      "type": "string",
      "format": "hidden"
    },
    "remote": {      
      "title": "Cloud Sync (Github / Bitbucket)",
      "type": "string",
      "description": "The remote git repository to push data"
    },
    "members": {
    	"title": "Sharing and Permissions",
      "description": "Provide email addresses and access levels",
      
    	"type": "array",
      "format": "table",
    	"items": {
    		"$ref": "#/definitions/person"
    	}
    },
    "tags": {
      "type": "array",
      "format": "hidden",
      "title": " "
    }    
  },
  "defaultProperties": ["members", "remote"],
  "definitions" : {
    "person": {
      	"type": "object",
      	"title": "Person",
      	"properties": {
      		"email": {
      			"type": "string",
      			"title": "Email",
      			"format": "email",
      			"propertyOrder": 2,
            "minLength": 4
      		},
          "roles": {
          	"title": "Access Level",            
          	"type": "array",
            "uniqueItems": true,            
            "format": "checkbox",
            "displayAs": "slider",
          	"items": {
          		"type": "string",
              "enum": ["read","write","admin"],
              "multiple": true              
          	},
          	"default": [
          		"read",
          		"write"
          	]
          }          
      	},
      	"defaultProperties": [
      		"email",
          "roles"
        ]      
    }
    
  },
  "options": {
    "editor": {
      "disable_array_reorder": true
    },
    "roles": ["admin"]
  }
  
}