module.exports={
  "$schema": "http://json-schema.org/draft-04/schema#",
  "type": "object",
  "title": "Folder Settings",
  "options": {
    "editor": {
      "no_additional_properties": true
    }
  },
  "properties": {
		"image": {
			"type": "string",
			"links": [
				{
					"href": "{{self}}"
				}
			],
			"title": "Icon / Image",
			"format": "url",
			"options": {
				"upload": true
			},
			"optional": true
		},
    "schema": {      
      "title": "Schema",
      "description": "Override the default data format for items in this folder",
      "type": "string",
			"enumSource": [
				{
					"title": "{{item.id}}",
					"value": "{{item.id}}",
					"source": "#/sources/schemas"
				}
			]
    },
    "view": {      
      "title": "View",
      "description": "Choose a view to show instead of edit form when items are opened <a style='cursor:pointer' onclick=\"document.location.href = document.location.href.replace('_settings','_views').replace('/doc/','/browser/')\" class='ui basic mini compact button'>Manage Views</a>",
      "type": "string",
			"enumSource": [
				{
					"title": "{{item.id}}",
					"value": "{{item.id}}",
					"source": "#/sources/views"
				}
			]      
    },
    "features": {
      "type": "object",
      "title": "Features",
      "properties": {
        "create": {
          "type": "boolean",
          "format": "checkbox",
          "title": "new items can be added",
          "default": true
        },
        "remove": {
          "type": "boolean",
          "format": "checkbox",
          "title": "existing items can be removed",
          "default": true          
        },
        "folders": {
          "type": "boolean",
          "format": "checkbox",
          "title": "sub-folders can be created",
          "default": false          
        }
        
      }
    },
		"tags": {
      "$ref": "/_schemas/shared/tags.json",
      "default": []
		},
    "title": {
      "title": "Folder Title",
      "type": "string"
    }    
  },
	"sources": {
		"schemas": ["_schemas"]
	},  
  "defaultProperties": ["title", "features", "schema"]  
}