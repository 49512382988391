import path from 'path'


import metaForPath from './modules/content/meta-for-path'
import api from './modules/authenticated-request'
import sync from './modules/content/sync'
import save from './modules/content/save'
import pathWithoutExtension from './modules/content/path-without-extension'
import frontSlash from './modules/content/front-slash'
import noFrontSlash from './modules/content/no-front-slash'
import commit from './modules/content/commit'
import contentGet from './modules/content/get'
import initJsonEditor from './modules/content/init-json-editor'
import _ from "lodash"
import utils from "./utils"
import elasticlunr from 'elasticlunr'


import jsonSchemaDeref from 'json-schema-deref-sync'
import getFolder from '../frontend/js/modules/get-folder'

// this is vanilla pug with the tiniest adjustment
// to include the browserfs shim
//var fs = require("browserfs/dist/shims/fs.js");
import fs from 'fs'
import pug from '../vendor/pug/packages/pug/lib/index'

export default (opts = {}) => {
  const {firebase, account_id} = opts
    
  var templateCache = {}
  
  var searchIndex = {}, depsIndex = {}, indexBuilt = false;
  
  const Content = {
    
    // export the internal utils
    exists: pathExists,

    hostname: hostname(),
    
    frontSlash: frontSlash,
    noFrontSlash: noFrontSlash,
    
    pathWithoutExtension: pathWithoutExtension,
    pathWithExtension: pathWithExtension,
    
    isFolder: isFolder,
    isHidden: isHidden,
    isView: isView,
    isJson: isJson,
    isHtml: isHtml,
    isSchema: isSchema,
    isImage: isImage,
    
        
    get: contentGet,
    metaForPath: metaForPath,
    isArchived: isArchived,
    resolveSchema: resolveSchema,
    getTemplatePath: getTemplatePath,
    displayOrdered: displayOrdered,
    
    commit: (opts) => commit(Object.assign({firebase, account_id}, opts)),
    
    sync: (opts) => sync(Object.assign({firebase, account_id}, opts)),
    
    save: (opts) => save(Object.assign({firebase, account_id, Content }, opts)),
    
    initJsonEditor: initJsonEditor,
    
    getSorted: (inPath, sortBy) => {
     return _.chain(Content.get(inPath))        
      .map((item) =>  {
        return Object.assign(item, {title: item.title ? item.title : item._basename})
      })
      .sortBy(sortBy)
      .value()  
    },
    
    getFolderSettings: (folderPath) => {
      return Content.get(folderPath + '/_settings.json')
    },
    
    writeFolder: (folderPath, values) => {
      const folderSettingsPath = folderPath + '/_settings.json'
      
      return Content.commit({targets: [
        {
          "address": folderSettingsPath,
          "payload": values,
          "action": "write"
        }         
      ]})
      
    },
    
    remove: (inPath) => {
      
      var targets = [{
        address: inPath,
        action: "remove"
      }]
      
      const htmlPath = pathWithExtension(inPath, 'html')
      if (isJson(inPath) && pathExists(htmlPath)) targets.push({
        address: htmlPath,
        action: "remove"
      })
        
      const assetPath = `_assets/${ pathWithoutExtension(inPath) }`
      console.log(`Searching for ${assetPath} to delete`)
      if ( pathExists(assetPath) ) targets.push({
        address: assetPath,
        action: "remove"
      })
      
      return Content.commit({targets})

      
    },
    
    clone: (source, destination) => {
      
      var targets = [{
        sourceAddress: source,
        address: destination,
        action: "copy",
        replaceAssetUrls : true
      }]
      
      if (pathExists(pathWithExtension(source, 'html'))) targets.push({
        sourceAddress: pathWithExtension(source, 'html'),
        address: pathWithExtension(destination, 'html'),
        action: "copy",
        replaceAssetUrls : true
      })
        
      const assetPath = `_assets/${ pathWithoutExtension(source) }`
      if (pathExists(assetPath))  {

        Content.get(`/${assetPath}`).forEach((asset) => {

          targets.push({
            sourceAddress: `${assetPath}/${asset._basename}`,
            address: `_assets/${ pathWithoutExtension(destination) }/${asset._basename}`,
            action: "copy"
          })
          
        })
          
        
      }

      return Content.commit({targets})
      
    },
    
    deploy: (productionDomain) => {
      return api({method: 'POST', url: `/deploy/${account_id}/${productionDomain}`, firebase: firebase}, {})
    },
    
    generate: (inPath, opts = {}) => {
      var {data, editMode, readOnly} = opts
      
      var html = "";
      data = data || Content.get(inPath)
      const tplPath = getTemplatePath(inPath, {data: data})
      
      if (tplPath) {
      
        try {
          return Content.render(tplPath, {editMode: editMode, doc: data, readOnly: readOnly})
        } catch(err) {
          console.log(err)
          return `Error rendering ${tplPath}`
        }
      
      }
    },
    regenerateFor: (inPath) => {      
      var regeneratePaths = [inPath]
      const originalItemSchema = Content.resolveSchema(inPath)
      
      
      if (originalItemSchema && originalItemSchema.$regenerate) {
        originalItemSchema.$regenerate.forEach((regeneratePath) => {
          regeneratePaths.push(regeneratePath)
        })
      }
      
      var targets = []      
      var views = []
      regeneratePaths.forEach((regeneratePath) => {
        const dependsOn = Content.searchDeps(regeneratePath).map((res) => res.ref)
        console.log('regenerateFor', regeneratePath, '=>', dependsOn)
        if (!dependsOn.length) return true
        dependsOn.forEach((targetPath) => {
          var schema
          var deps = []
          if (content.isSchema(targetPath)) {
            schema = Content.getSchema(targetPath)
            if (schema && schema.$generate) {
              Content.searchDeps(targetPath).forEach((result) => {
                const matchedPath = result.ref
                if (Content.isFolder(matchedPath)) {
                  console.log('Scanning', matchedPath)
                  Content.get(matchedPath).forEach((doc) => {
                    console.log('Found', doc)
                    if (Content.isJson(doc._id)) { 
                      deps.push(doc._id)
                    }
                  })
                }              
              })
            }
          } 
          else if (Content.isView(targetPath)) {
            views.push(targetPath)          
          } else { 
            schema = resolveSchema(targetPath)
            if (schema && schema.$generate) deps.push(targetPath)
          }
        
          deps.forEach((depPath) => {
          
            const html =  Content.generate(depPath, {readOnly: true})
      
            if (html) targets.push({
             address: `${pathWithoutExtension(depPath)}.html`,
             action: "write",
             payload: html
           })        
           if (views) console.log("Also found these views", views)
           
          })
        
        })
        
      })
      
      return Content.commit({targets})
        .then(() => Promise.all(views.map((filename) => Content.regenerateFor(filename) )))
        
    },
    
    render: (inTemplate, locals = {}) => {
      const {editMode, data, dependencies, noCache, filename, readOnly, production} = locals
      const defaultLocals = { _: _, utils: utils, Content: Content}
      var stringMode = false
      //console.log('render', {inTemplate, locals})
      if (!inTemplate) { console.log(`inTemplate (1st arg) is required.`); return ""; }
      if (!fs.existsSync(inTemplate)) { 
        console.log(`render() info: inTemplate is not a file, processing as pug string\n ${inTemplate}`)
        stringMode = true
      } 

      try {
        var tpl
        
        if (stringMode) tpl = pug.compile(inTemplate, { filename: filename })
        else {
          if (!noCache && templateCache[inTemplate]) tpl = templateCache[inTemplate]
          else if (noCache || !templateCache[inTemplate]) tpl = pug.compileFile(frontSlash(inTemplate))
          if (!noCache && tpl) templateCache[inTemplate] = tpl          
        }
        
        html = tpl(Object.assign(defaultLocals, locals))
      
      } catch(err) {
        console.log(err)
        return ""
      }
       
      html = html.replace(/(['"\(])\/?(_assets\/)/g, `$1${hostname()}/$2`);
      if (editMode) html = html.replace(/(\<a .*?href=)(['"]?)[^ \>]+(.*?\>)/ig, `$1$2$2 onclick="return false;" "$3`);
      if (readOnly) html = html.replace(/data-property=".*?"/, '').replace(/data-context=".*?"/, '')
      
      return html
      
    },
    
    renderBlock: (opts) => {
      const {block, doc, dependencies, editMode, noCache} = opts    
    
      try {
        const readOnly = block.dataPath ? true : false // always turn off editMode for shared data blocks
        const contextAttr = `data-context="blocks.${block.srcIdx}"${ readOnly ? ' data-readonly=""' : "" }`
        const blockData = resolveBlockData(block)
        const blockLocals = Object.assign(blockData, {doc: doc, dependencies: dependencies, editMode: editMode, noCache: noCache, readOnly: readOnly })
        if (blockData && blockData.filename) return `<span ${contextAttr}>` + Content.render(blockData.filename, blockLocals, noCache) + `</span>`
      } catch(err) {
        console.log(err)
        return(`<h1>error loading ${ block.filename }</h1>`)    
      }
    
    },
    
    renderBlocks: (opts) => {
      const defaultEmptyMsg = 'Hi! This is a fresh page. You can put something here using the "Manage Blocks" link above!'
      const {doc, dependencies, editMode, emptyMsg} = opts   
      out = ""
      if (doc && doc.blocks && doc.blocks.length) {
        out += '<span id="content" data-context="">'
        displayOrdered(doc.blocks).forEach((block, idx) => {
          if (block.filename) out += Content.renderBlock(Object.assign(opts, { block: block }))
        })
        out += "</span>"
      }
      else out += `<div style="border: 1px solid #777; padding: 2rem; margin: 0 auto; width: 50%; margin-top: 25vh;">${emptyMsg ? emptyMsg : defaultEmptyMsg}</div>` 
      return out   
    
    },
        
    search: (text, opts = {}) => {
      //return searchIndex.search(text, {fields: {title: {boost: 1}, tags: {boost: 2}, all: {boost: 0} }});
      
      if (!indexBuilt) Content.buildSearchIndex()      
      return searchIndex.search(text, Object.assign({ 
        bool: "AND",
        expand: true
      }, opts ));
      
    },
    
    searchDeps: (searchFor) => {
      if (!indexBuilt) Content.buildSearchIndex()      
      return depsIndex.search(searchFor,  { 
        bool: "AND",
        expand: true,
        fields: {deps: {}}
      });
    },
    
    buildSearchIndex: () => {
      searchIndex = elasticlunr(function() {
          this.addField('_id');
          this.addField('_basename');
          this.addField('title');
          this.addField('allText')
          this.addField('tags');          
          this.setRef('_id');
      })
      depsIndex = elasticlunr(function() {
          this.addField('_id');
          this.addField('deps')
          this.setRef('_id');
      })
      
      elasticlunr.clearStopWords();
      
      Content.get('/', {recursive: true, includeSelf: true}).forEach((doc) => {
        if (doc && doc.tags && Array.isArray(doc.tags)) doc.tags = doc.tags.join(', ')
        if (isJson(doc._id) && !isSchema(doc._id)) {
          
          const allText = JSON.stringify(_.omit(doc, ['_id', '_basename', '_metadata', 'tags', 'template']))
            .replace(/\"[a-z0-9_\-]+\"\:/ig, '')
            .replace(/[^a-z]/ig, ' ')
            .replace(/ +/g,' ')
          searchIndex.addDoc(Object.assign(doc, {allText: allText}));            
                    
        }
        else if (isView(doc._id)) {
          searchIndex.addDoc(Object.assign(doc, {allText: doc.src }));
          console.log("added index for", doc._basename)
        }
        
        if (isJson(doc._id) || isFolder(doc._id)) {
          var deps = JSON.stringify(_.omit(doc, ['_id', '_basename', '_metadata', 'tags'])).match(/"([a-z0-9\-_\/\.]+\.(json|pug))"/g);
          if (deps) {
            deps = deps.map((dep) => dep.slice(1, dep.length -1) )
          }
          //console.log("added index for", doc._basename)        
          depsIndex.addDoc({_id: doc._id, deps: deps})           
        }
        
        if (isView(doc._id)) {
          const docSrc = fs.readFileSync(doc._id).toString()
          var deps = docSrc.match(/"([a-z0-9\-_\/\.]+\.(json|pug))"/g);
          if (deps) {
            deps = deps.map((dep) => dep.slice(1, dep.length -1) )
          }
          //console.log("added index for", doc._basename)        
          depsIndex.addDoc({_id: doc._id, deps: deps})
          searchIndex.addDoc(Object.assign(doc, {allText: docSrc}));            
          
        }
        
      })
      indexBuilt = true
    },
    
    getSearchDropdownResults:  function(settings, callback) {
      const searchFor = settings.urlData.query
      var out = { results: [] }
      
      Content.search(searchFor).forEach((result) => {
        console.log(result.ref)
        const searchEntry = Content.get(result.ref)
        if (searchEntry) { 
          const parts = path.dirname(searchEntry._id).split('/')
          
          const desc = 
          out.results.push({
            title: (searchEntry.title ? searchEntry.title : pathWithoutExtension(searchEntry._basename)),
            description: (parts[(parts.length -1)] ? parts[(parts.length -1)] : 'Base Folder'),
            url: `/#/${account_id}${searchEntry._id}`
          })
        }
      })
      callback(out)
    },
    
    filterDocForEditor: function(doc) {
      const inPath = doc && doc._id ? doc._id : utils.currentDataPath()
      if (Content.isSchema(inPath) ) return JSON.stringify(doc, {}, '\t')
      else if ( Content.isView(inPath)  ) return doc
      else return _.omit(doc, ['id','_id','_basename','_metadata','createdAt','updatedAt','creator','commits','createdByEmail','lockHolder',"lockedAt"])
    },
    
    processSchema: function(s) { 
      var processedSchema = jsonSchemaDeref(_.cloneDeep(s));
    
      if (typeof(s.sources) != 'object') return processedSchema;
      else { // lookup enum Sources from repo
        Object.keys(processedSchema.sources).forEach((idx) => {
      
      
          var sourcePaths = processedSchema.sources[idx];
          var refs = [];
      
          if (!_.isArray(sourcePaths)) sourcePaths = [sourcePaths];
      
      
          sourcePaths.forEach((sourcePath) => {
            // populate id's and filter out ^_ items
        
            refs = refs.concat(
              Content.get(sourcePath, {recursive: true}).map((item) => {
                if (item && item._id && Content.isJson(item._id)) return Object.assign(item, {id: item._id})
                else return {}
              })
              /*
              $.map( getFolder(sourcePath), (item, key) => {
                
                item.id = `${sourcePath}/${key}`;
                const itemIsObject = typeof(item) == 'object';
                const itemIsFolder = typeof(item._settings) == 'object';
                const doesNotStartWithUnderscore = key.indexOf('_') != 0;
                if (itemIsObject && doesNotStartWithUnderscore && !itemIsFolder) return item;
              })
              */
          
            );
        
          });
      
          refs.unshift({
            id: "",
            title: ` -- ${idx} -- `
          })
          utils.deepReplace( processedSchema, `#/sources/${idx}`, refs );
      
        })
        return processedSchema;
      }
    }, 
        
    getSchema: (p) => {
      if (typeof(p) != 'string') return false; 
      else { 
        return Content.processSchema(utils.loadDataFromFile(p))
      }
    },
    
    getExternalRefs: function(schema) {
      var refs = {};
      var merge_refs = function(newrefs) {
        for(var i in newrefs) {
          if(newrefs.hasOwnProperty(i)) {
            refs[i] = true;
          }
        }
      };
  
      if(schema && schema.$ref && typeof schema.$ref !== "object" && schema.$ref.substr(0,1) !== "#") {
        refs[schema.$ref] = true;
      }
  
      for(var i in schema) {
        if(!schema.hasOwnProperty(i)) continue;
        if(schema[i] && typeof schema[i] === "object" && Array.isArray(schema[i])) {
          for(var j=0; j<schema[i].length; j++) {
            if(typeof schema[i][j]==="object") {
              merge_refs(Content.getExternalRefs(schema[i][j]));
            }
          }
        }
        else if(schema[i] && typeof schema[i] === "object") {
          merge_refs(Content.getExternalRefs(schema[i]));
        }
      }
  
      return refs;
    },
    
  }
  
  return Content
  
  
  function resolveBlockData(block) {
    // a normal block won't have a dataPath, if so, just return it
    if (!block.dataPath || !fs.existsSync(block.dataPath))  return block
    else {
      // attempt to resolve dataPath to a block with a matching filename
      dataPathContents = utils.loadDataFromFile(block.dataPath)      
      if (dataPathContents && dataPathContents.filename && dataPathContents.filename == block.filename) return dataPathContents
      else {
        // loop thru the data in dataPath to see if it has a .blocks array        
        if (dataPathContents.blocks && dataPathContents.blocks.length) {
          for (var i = 0; i < dataPathContents.blocks.length; i++) {
            // if a block with matching filename is found, return it
            var dataPathBlock = dataPathContents.blocks[i]
            if (dataPathBlock.filename == block.filename) return dataPathBlock
          }          
        }
        return block // if nothing was found, give back what we were sent
      } 
    }
  }
  
  function frontSlash(inPath) { return (inPath && inPath.indexOf('/') != 0 ? `/${inPath}` : inPath) }
  
  function pathExists(p) { return fs.existsSync(frontSlash(p)) }
  
  function isFolder(inPath) { return inPath && fs.existsSync(frontSlash(inPath)) && fs.existsSync(`${frontSlash(inPath)}/_settings.json`) }
      
  function isHidden(inPath) { return inPath && path.basename(inPath).indexOf('_') == 0 }
  
  function isView(inPath) { return inPath && [".pug",".jade"].includes(path.extname(inPath)) }

  function isJson(inPath) { return inPath && path.extname(inPath) == '.json' }

  function isHtml(inPath) { return inPath && path.extname(inPath) == '.html' }
  
  function isSchema(inPath) { return inPath && (inPath.match(/^\/?_schemas\//) || inPath.match(/\.pug\.schema\.json$/) ) }
  
  function isImage(inPath) { return inPath && inPath.match(/\.jpg$|\.jpeg$|\.png$|\.gif$|\.svg$/i) }
  
  function isArchived(item) { return typeof(item) == 'object' && item.tags && item.tags.includes('Archived') }
  
  function hostname() { return `https://storage.googleapis.com/${account_id}.flowpub.com` }
     
  function pathWithExtension(p, ext) {
    if (!ext) { console.log('no ext supplied to pathWithExtension()'); return p }
    const extname = path.extname(p)
    const outExt = ext.indexOf('.') == 0 ? ext : `.${ext}` // makes sure ouput  has a . before new ext
    if (!extname) return p
    else return p.replace(new RegExp(`${extname}$`), outExt)    
  }
  
  function getTemplatePath(inPath, opts = {}) {
    const data = opts.data || Content.get(inPath)
    
    if (data.template) return `${data.template}.pug`
    else if (data.filename) return data.filename;
    else { 
      const schema = resolveSchema(inPath) 
      if (schema && schema.$views && schema.$views.default) return schema.$views.default.filename        
    }
  }
  
  function displayOrdered(inCollection) {
    var collection = _.cloneDeep(inCollection)
    return _.chain(collection).map((item, srcIdx) => {item.srcIdx = srcIdx; return item }).sortBy((item) => {return parseInt(item.displayOrder)}).value()
  }
  
  function resolveSchema(inPath, originalPath, doneRoot) {
  
    //console.log('inPath', inPath);
    //console.log('doneRoot', doneRoot);
    const debug = false
  
    // setup recursion
    if (typeof(originalPath) == "undefined") { 
      originalPath = (' ' + inPath).slice(1)
        
      // one time test for details in the file itself
      if (pathExists(`${originalPath}.json`)) {
        if (debug) console.log('Looking for schema in', originalPath)
        const obj = Content.get(originalPath)
        if (obj.schema) {
          if (pathExists(obj.schema)) return Content.getSchema(obj.schema)
        }
        else if (obj.template) {
          const templateSchemaPath = path.extname(obj.template) ?  `${obj.template}` : `${obj.template}.pug.schema.json`
          if (debug) console.log('Looking for template schema for', templateSchemaPath)
          if (pathExists(templateSchemaPath)) return Content.getSchema(templateSchemaPath)
        }
        else if (obj.filename) {
          if (debug) console.log('Looking for block template schema for', obj.filename)
          const blockTemplateSchemaPath = `${obj.filename}.schema.json`
          if (pathExists(blockTemplateSchemaPath)) return Content.getSchema(blockTemplateSchemaPath)
        }
      }
    }
  
  
    const originalDir = path.dirname(originalPath)
    //console.log('originalDir', originalDir)
    const originalFilename = path.basename(originalPath)
  
    
    //
    // Globally map certain file extensions directly to a core/shared schema
    //

    if (Content.isSchema(originalFilename)) return utils.loadDataFromFile('/_schemas/shared/json.json')
    if (Content.isView(originalFilename)) return utils.loadDataFromFile('/_schemas/shared/pug.json')
    
    var schema;
    const fSettingsPath = `${inPath ? inPath + '/' : ''}_settings.json`
    if (debug) console.log(`Scanning ${fSettingsPath}`)
  
    const fSettings =  utils.loadDataFromFile(fSettingsPath)
  
    if (fSettings && fSettings.schema) schema = Content.getSchema(fSettings.schema) 
    if (schema) return Content.processSchema(schema);
    else { 
      function lastResort() {
        if (debug) console.log('schema search: last resort', originalPath)
        const parts = originalDir.split('/');
        const top = utils.loadDataFromFile(`${originalDir}/${parts[0]}.json`)
        const second = utils.loadDataFromFile(`${originalDir}/${parts[0]}/${parts[1]}.json`)
        if ( top && top.properties  ) return Content.processSchema(top);
        else if ( second && second.properties ) return Content.processSchema(second);
        else {
          // root _settings.json
          if (debug) console.log(`Scanning root /_settings.json`)
        
          const rootSettings = utils.loadDataFromFile(`/_settings.json`)          
          if (rootSettings.schema) { 
            console.log( Content.getSchema(rootSettings.schema))
            return Content.getSchema(rootSettings.schema)
          }
        }
      }
    
      var parentPath = utils.parentForPath(`${inPath.replace(/^\//,'')}`);
      //console.log('parentPath', parentPath);
      // break recursion if we've reached the root
      if (!doneRoot) return content.resolveSchema(parentPath, originalPath, (!parentPath || parentPath == inPath) ? true : false );  
      else return lastResort();
    }

  }

  
  
}
