const basename = require('basename');

import path from 'path'
import fs from 'fs'
import s from 'underscore.string'
import api from '../frontend/js/modules/authenticated-request'
import noFrontSlash from './modules/content/no-front-slash'
import mkdirp from './modules/content/mkdirp'
import defaultDoc from 'json-schema-defaults'
import _ from 'lodash'
import pug from '../vendor/pug/packages/pug/lib/index'

import jsonSchemaDeref from 'json-schema-deref-sync'


_.mixin(require("lodash-deep"));

// some view helpers to DRY up simple string manipulations
var utils = module.exports = {
  "loadDataFromFile": function(p) {
    try {
      const loadPath = p.match(/\.json$/) ? p : `${p}.json`
      return (fs.existsSync(loadPath)) && JSON.parse(fs.readFileSync(loadPath).toString())
    } catch (err) {
      console.log(`Error in ${p}`, err)
      return {}
    }
  },
  "basename": function(s) {
    return basename(s)
  },
  "getLocalConfig": function(setting) {
    try {
      return fs.readFileSync(`/.foxflow-local/${setting}`)
    } catch(err) { return false }    
  },
  "setLocalConfig": function (setting, value) {
    try {
      mkdirp('/.foxflow-local')
      if (value) fs.writeFileSync(`/.foxflow-local/${setting}`, value)
      else fs.unlinkSync(`/.foxflow-local/${setting}`)
    } catch(err) {}    
  },
  "currentAccountId": function() {
    const cleaned = location.hash.replace(/^#\/?/,'')
    const slash = cleaned.indexOf('/')
    return cleaned.substr(0, ((slash > 0)? slash : cleaned.length))
  },
  "currentPath": function() {
    return location.hash.replace(/^#\/?/,'').replace(/\/?$/,'')
  },  
  "currentDataPath": function() {
    return location.hash.replace(/^#\/?[^\/]+\/?/,'')
  },
  "pathIsFolder": function(p) {
    return fs.existsSync(`${p}/_settings.json`) || (fs.existsSync(p) && !path.extname(p))
  },
  "extname": function(p) {
    return path.extname(p)
  },
  "parentForPath": function(p) {
    if (!p) p = utils.currentPath();
    const parts = p.split('/');
    return p.replace(new RegExp('/' + parts[parts.length - 1] + '$'), '');
  },  
  "currentAddress": function() {
    return utils.pathToAddress(utils.currentDataPath())
  },
  "isHidden": function(base) {
    
    if (!base) base = '';
    var isUnderscore = base.match(/^\_.*?$/); 
    var isDevMode = utils.getLocalConfig('dev-mode') && !base.match(/^_settings$/)
    const out = ( isUnderscore && !isDevMode ) ? 'hidden' : ''
    return out
  },
  "pathToAddress": function(path) {
    return "['" + path.replace(/\//g,"']['") + "']";
  },
  "resolveTitle": function(item, id) {    
    return item.title ? item.title : ((_.get(item, '_settings') && _.get(item, '_settings').title) ? _.get(item, '_settings').title : id.replace(/\.json/,''))
  },
  "isNewDoc": function() {
    return utils.currentPath().match(/\/new$/)
  },
  "defaultDoc": function(schema) {
    return defaultDoc(schema)
  },
  "thumbnail": function(p) {
    var t = _.get(window.thumbnails, utils.pathToAddress(`${p.replace(/\.json/,'')}/image`));
    return t ? t : ''
  },
  "parentName": function(p) {
    if (!p) p = utils.currentPath()
    var breadcrumbs = p.split('/');
    return (breadcrumbs && breadcrumbs.length >= 1) ? breadcrumbs[breadcrumbs.length - 2] : undefined;
  },
  "humanTitle": function(context) {
    var str = ( (context && typeof(context) == 'object') ? (context.title ? context.title : basename(context.id)) : context );
    return s(str).humanize().titleize().value()
  },
  "truncate": function(context, chars) {
    var str = ( (context && typeof(context) == 'object') ? (context.title ? context.title : basename(context.id)) : context );
    return s(str).truncate(chars).value()
  },
  
  "slugify": function(context) {
    var str = ( (context && typeof(context) == 'object') ? (context.title ? context.title : basename(context.id)) : context );
    return s(str).slugify().value()
  },
  "deepReplace": function(hay, needle, replacement) {
    if (typeof hay == 'object') {
      for (var i in hay) {
        utils.deepReplace(hay[i], needle, replacement) == true ? hay[i] = replacement : 1;
      }
    }  
    return new RegExp(needle).test(hay) || "done";
  },  
  "parseFormFiles": (valuesIn, fields, $formElement) => {
    var values = _.cloneDeep(valuesIn);
    var files = {};
        
    fields.forEach((field) => {
      var name = field.name;
      if (field.format == 'file') { 
        if (_.get(values, name)) {
          var $field = $formElement.find(`input[name="${name}"]`);
          files[name] = $field[0].files[0];        
        }
        // run this whether or not values[name] exists so as not to overwrite
        // exisitng images when a new one is not uploaded
        delete(values[name]);
      }
    });
    
    return {
      values: values,
      files: files
    }
  
  },
  "getSorted": (collection, sortBy) => {
     return _.chain(collection)        
      .map((item, id) =>  {
        return Object.assign(item, {id: id})
      })
      .sortBy(sortBy)
      .value()  
  },
  "resolveDependencies": function(paths, deps) {
    _.map(paths, function(p) {
      return deps[p]
    })
  },  
  "templatesAsFolder": function(contentTemplateTree) {
    return _.deepMapValues(contentTemplateTree, (item) => {
      if (typeof(item) == 'function') return { type: "template" }
      else return item;
    });
  },
  "html2canvas" : require("../vendor/html2canvas.js"),  
  
  "readUploadedFiles": (tag, tagToUpdate, updateAfter = true) => {
  
    var populatedFileFields = [];
    
    $('input[type=file]').each((i, field) => {
      if (field.files && field.files[0]) populatedFileFields.push(field);
    });
    
    console.log('populatedFileFields', populatedFileFields);
    
    return Promise.all(_.map(populatedFileFields, (field) => {
      
      
      var idx = $(field).closest('[data-src-idx]').attr('data-src-idx');
      var file = field.files[0];
      var fieldName = $(field).closest('[data-schemapath]').attr('data-schemapath').replace(/^root\./,'');
      var schema, schemaPath, schemaConfigPath;
      schemaConfigPath = `properties.${fieldName}`
      
      //if ( tag.blocks) {
      if ( tag.block) {
        var blockAddr = 'blocks[' + idx + '].';
        var block = tag.block
        schema = jsonSchemaDeref(_.cloneDeep(tag.schemaForBlock(block)));
        schemaPath = `${blockAddr}${fieldName}`;
      } else if ( tag.blocks) {
        var blockAddr = 'blocks[' + idx + '].';
        var block = tag.blocks[idx];
        schema = jsonSchemaDeref(_.cloneDeep(tag.schemaForBlock(block)));
        schemaPath = `${blockAddr}${fieldName}`;
      } else {
        schema = jsonSchemaDeref(tag.schema);
        schemaPath = fieldName;
        
        if (!_.get(schema, `properties.${fieldName}.type`)) {
          var parts = fieldName.split('.')
          if (parts.length == 3) {
            var arrayPath = `properties.${parts[0]}.items.properties.${parts[2]}`
            console.log(arrayPath)
            if (_.get(schema, arrayPath)) schemaConfigPath = arrayPath
          } 
        }
        
      }
      
      console.log('processing file', fieldName, schemaPath, schemaConfigPath, schema, block);
      
      tagToUpdate.uploadBlobs = tagToUpdate.uploadBlobs || {};
      
      return new Promise((resolve, reject) => {
      
        if (!file.type.match('image') || file.type.match('svg') || file.type.match('png') || file.type.match('gif')) {
          // read non-image file 
          var reader  = new FileReader();

          reader.addEventListener("load", () => {
            //console.log(reader.result);
          
            var blob = new Blob([reader.result]);
            //console.log(blob);
            tagToUpdate.uploadBlobs[schemaPath] = {name: file.name, data: blob};
            var objUrl = window.URL.createObjectURL(blob);
            _.set(tagToUpdate.data, schemaPath, objUrl)
            resolve();
            
          }, false);

          reader.readAsArrayBuffer(file);
          
        } else {
          var imageOpts = {
            canvas: true,
            maxWidth: 1920,
            maxHeight: 1920                 
          };
          
          if (schema) {
            var propImageOpts = _.get(schema, `${schemaConfigPath}.options.image`);
            if (typeof(propImageOpts) == 'object') imageOpts = Object.assign(imageOpts, propImageOpts);
            console.log(`Image Opts for ${fieldName}`, imageOpts)
          }
          
          loadImage.parseMetaData(file, function (data) {
            if (data.exif) {
              imageOpts.orientation = data.exif.get('Orientation')
            }
          });
          
          loadImage(file, 
            (canvas) => {
              canvas.toBlob((blob) => {
                console.log(blob, schemaPath);
                var objUrl = window.URL.createObjectURL(blob);
                tagToUpdate.uploadBlobs[schemaPath] = { name: `${utils.slugify(schemaPath)}.jpg`, data: blob};
                _.set(tagToUpdate.data, schemaPath, objUrl);
                
                resolve();
              },
              'image/jpeg', 0.7);
            },
            imageOpts
          );
          
        }
          
      })
      
    })).then(() => {
      
      $('.ui.dimmer.processing').dimmer('hide');
      $('.ui.button.back-to-document').hide();
      $('.section-settings,.content-picker').find('i.icon.close').trigger('click'); // close sub-modals
      if (updateAfter) tagToUpdate.update();
      
    })
  
  
  },
  "generatePDF": function(framePath, opts) {
    // NB -- this will cycle through the framePath iframe content's .page elements (elements with class="page")
    opts = opts || {};
    ratio = opts.ratio || 2;
    width = opts.width || 1000;  // the native width in pixels of the pages
    height = opts.height || 1292; // the native height in pixels of the pages
    quality = opts.quality || 0.85;
    
    
    var pdfDoc = new jsPDF('p', 'in', [8.5, 11]);
    
    var $contentBody = $(window.document).find('body').find(framePath).contents().find('body');
    $contentBody.remove('script');
    $contentBody.css('width', (width * ratio) + 'px');
    
    var pages = [];
    
    $contentBody.find('.page').each((idx, e) => {
      
      $(e).css('transform',`scale(${ ratio })`);
      $(e).css('margin-left',`${(width / ratio)}px`);
      if (idx == 0) $(e).css('margin-top',`${(height / ratio)}px`);
      /*
      $(e).css('width',`${width * ratio}px`);
      $(e).css('min-height',`${height * ratio}px`);   
      $(e).css('max-height',`${height * ratio}px`);  
      $(e).css('overflow','hidden');  
      $(e).css('background-size','100%');   
         
      $(e).css('position','relative');
      //$(e).css(`margin','0 ${width / ratio}px`);
      $(e).css('top', `${height / 2 * ratio}px`);
      */
      pages.push(e);
    });
        
    return Promise.all(
      pages.map(function(page) {   
        console.log('processing', $(page).attr('class'));
        return utils.html2canvas( page, {
            useCORS: true, 
            logging: true,
            width: width * ratio,
            height: height * ratio
          })
          .catch((err) => {
            console.log(err);
          })
          .then((canvas) => {
            console.log('got shot', canvas)
            return canvas;
          })
          
      })
    )
    .then(function (canvases) {
      console.log('canvases', canvases);
      
      // need to do this here in a then() -- otherwise 
      // Promise.all() will complete in a somewhat randomized order
      _.each(canvases, (canvas, idx) => {
        pdfDoc.addImage(canvas.toDataURL('image/jpeg', quality), 'JPEG', 0,0,8.5,11);
        if (idx < canvases.length - 1) pdfDoc.addPage();
        
      })
    })
    .then(() => {
      return pdfDoc.save('document.pdf')
    })
    .catch((err) => {
      console.log(err);
    })
  }
  
};