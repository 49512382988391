const Foxbase = require('foxbase');

module.exports = (firebase) => {
 
  if (!firebase) { 
    console.log("Must pass firebase into model init.")
    return { }    
  }
  const profiles = new Foxbase.UserModel('profiles', { 
    firebase: firebase,
    blobFields: ['photoURL'],
    "form": {
      fields: [
        { name: "displayName", label: "Name"},
        { name: "email", label: "Email Address"},
        { name: "dev", label: "Development Mode", format: "checkbox", description: "Enable development workspace server."},
        { name: "photoURL", format: "file", contexts: ["hidden"]}
      ],
      validates: {
        name: "empty",
        email: "empty"
      }
    },
  })

  const accounts = new Foxbase.BaseModel('accounts', {
    firebase: firebase,
    blobFields: ['logo'],
    rootChildren: ['writes','folders', 'thumbnails'],
    "form": {
      fields: [
        { name: "name", label: "Organization Name", placeholder: "Organization Name" },
        { name: "id", label: "Account Name", placeholder: "your-company-name"}
      ],
      validates: {
        name: "empty",
        id: "empty"
      }
    }  
  })


  const members = new Foxbase.Model('accounts', 'members', { 
    firebase: firebase,
    nested: true,
    "form": {
      fields: [
        { name: "email", label: "Email Address", placeholder: "i.e. collaborator@somedomain.com", helpText: "A foxflow.com user with this verified email will have full access to this organization."}
      ],
      validates: {
        email: "empty"
      }
    },
  })
  
  return {
    profiles: profiles,
    accounts: accounts,
    members: members
  }
  
}
