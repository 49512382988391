var riot = require('riot');
module.exports = 







riot.tag2('breadcrumbs', '<span if="{crumbs &amp;&amp; crumbs.length}"> <span each="{crumb, idx in crumbs}"><a class="slight-title-color" href="/#{crumb.link}">{isDevMode ? crumb.text : utils.humanTitle(crumb.text)}<span if="{(idx &lt; (crumbs.length -1))}"><i class="icon angle right"></i></span></a></span></span>', '', '', function(opts) {
var _this = this;

this.isDevMode = this.parent.isDevMode;
this.utils = this.parent.utils;
this.on('mount', () => _this.update());

this.on('update', () => {

  if (opts.path) {
    var link = '';
    var crumbs = [];

    opts.path.split('/').forEach(function (crumb) {
      link += '/' + crumb;
      crumbs.push({
        link: link,
        text: crumb
      });
    });
    _this.crumbs = crumbs;
  } else _this.crumbs = [];
});
});