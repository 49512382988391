import riot from 'riot';
import router from 'riot-route';
import sync from '../../shared/modules/content/sync'
import fs from 'fs'
import path from 'path'
import updateUserProfile from './modules/update-user-profile'
import authenticatedRequest from './modules/authenticated-request'
import moment from 'moment';
import _ from 'lodash'
import firebase from 'firebase'
import initModels from '../../shared/models'
import initContent from '../../shared/content'

const bulk = require('bulk-require')
const sharedSchemas = bulk(__dirname, ['../../shared/schemas/**/*.json'])['..']['..']['shared']['schemas']
const tags = bulk(__dirname, ['../riot/*.pug'])['..']['riot']
require('./editor-setup.js')

firebase.initializeApp(require('../../../config/firebase'))

const utils = require('../../shared/utils')

// only global for dev/testing
//window._ = _
window.utils = utils

const defaultOpts = { 
  riot: Object.assign(riot, {router: router}),
  firebase: firebase,
  _: _,
  models: initModels(firebase),
  utils: utils,
  noty: Object.assign(require('noty'), {defaults: require('../../vendor/noty-config')})
}

const routeHandler = (p) => {
  
  const loc = location.hash.replace(/^#\/?/,'');    
  if (!loc) { return router('/accounts') }
  const parts = loc.split('/');
  
  var accountSwitched
  const thisRoutesAccountId = parts[0]
      
  if (thisRoutesAccountId) {
    
    const accountChanged = (!window.currentAccountId || thisRoutesAccountId != window.currentAccountId)
    const account_id = window.currentAccountId = thisRoutesAccountId
    
    
    //console.log('accountChanged', accountChanged, `thisRoutesAccountId:${thisRoutesAccountId}   window.currentAccountId:${ window.currentAccountId}`)
    
    const content = initContent({firebase: firebase, account_id: account_id})
    window.content = content
    
    const pickBrowseOrEdit = () => {
      
      if (window.action) window.oldAction = window.action

      var dataPath = utils.currentDataPath()
        if (!dataPath || utils.pathIsFolder(dataPath)) window.action = "browse"
      else if (fs.existsSync(`${dataPath}.json`) || fs.existsSync(dataPath) || utils.isNewDoc() || dataPath.match(`\.pug\.schema\.json$`) ) { 
        if (content.isSchema(dataPath) || content.isView(dataPath)) window.action = 'src'
        else window.action = "edit"
      }
      else router(`/${account_id}`)

      if (window.action != window.oldAction || window.action == "browse") window.tag = riot.mount('body', window.action, Object.assign(defaultOpts, { fs: fs, user: firebase.auth().currentUser, firebase: firebase, content: content, account_id: account_id, utils: utils }))

    }
    
    if (!accountChanged) return pickBrowseOrEdit()
    else { // Account selection has changed (1st time or switched)
      
      
      if (window.tag) { 
        window.tag[0].loaded = false
        window.tag[0].loaderText = `Loading ${account_id} workspace...`
        window.tag[0].update()
      }
      
      
      BrowserFS.configure({
          fs: "AsyncMirror",
          options: {
            sync: {
              fs: "InMemory"
            },
            /*
            async: {
              fs: "XmlHttpRequest",
              options: {
                index: fsIndex,
                baseUrl: `https://storage.googleapis.com/foxflow-${account_id}/`
              }
            }*/
            async: {
              fs: "IndexedDB",
              options: {
                storeName: `foxflow-${account_id}`
              }
            }
          
          }
        }, function(e) {
        if (e) {
          // An error happened!
          throw e;
        }
        
        
        

        
        
        // trying without the alreadyInitialized bit
        
        firebase.database().ref(`accounts/${account_id}`).on('value', function(snapshot) {
          const account = window.account = snapshot.val();
          var profile = snapshot.val();
        })
      
        firebase.database().ref(`accounts-thumbnails/${account_id}`).on('value', function(snapshot) {
          window.thumbnails = snapshot.val();
          if (window.tag && window.tag.length && window.action == 'browse') window.tag[0].update();
        })
        
        //if (alreadyInitialized) pickBrowseOrEdit()
        
        sync({fs: fs, account_id: account_id, shared: sharedSchemas, recursive: true, firebase: firebase })
          .then(() => {
            //if (!alreadyInitialized) pickBrowseOrEdit()
            pickBrowseOrEdit()
          })
          .catch((err) => {
            console.error(err)
          })
        
        
      }) // End BrowserFS wrapper
      
      
      
      
      
    } // end of accountChanged block
    
  }    

} 


firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    
    updateUserProfile({user: user, db: firebase.database() }).then(() => {
    
      router('/profile',() => {
        window.tag = riot.mount('body', 'profile', defaultOpts)
      })

      router('/accounts',() => {
        window.tag = riot.mount('body', 'accounts', Object.assign(defaultOpts, {api: authenticatedRequest, firebase: firebase }))
      })
    
      router('/account/*', (account_id) => {
        window.tag = riot.mount('body', 'account', Object.assign(defaultOpts, {account_id: account_id}))
      })
  
      router('..',routeHandler);
      router('', routeHandler);      

      router.start(true);

    })

  } else if (document.location.pathname != "/account.html") {
    document.location.href = "/account.html"
  }
})







