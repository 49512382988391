var riot = require('riot');
module.exports = 
riot.tag2('add-block-slider', '<div class="add-block-slider {\'active\' : parent.addBlockOpen}"> <div if="{parent.addBlockOpen}"> <div class="ui menu top attached"> <div class="borderless item" style="cursor:pointer;"><i class="icon home" onclick="{crumbNav}" data-link=""></i><i class="icon angle right" if="{crumbs &amp;&amp; crumbs.length}"> </i><span if="{crumbs &amp;&amp; crumbs.length}"> <span each="{crumb, idx in crumbs}"><a onclick="{crumbNav}" data-link="{crumb.link}">{utils.humanTitle(crumb.text)} <span if="{(idx &lt; (crumbs.length -1))}"><i class="icon angle right"></i></span></a></span></span></div> </div> <div class="ui basic segment" style="margin-top:0;"> <div class="ui basic horizontal segment"> <div class="ui vertical segment"> <div class="ui mini button right floated" onclick="{blocks}" if="{content.isFolder(\'_views/blocks\')}">Blocks</div>Navigate folders and click a page, component, or template to add it to the bottom </div> <div class="ui basic vertical segment"> <div class="ui two cards"> <virtual data-is="thumbnail" hide_menu="{true}" hide_tags="{true}" schema="{schema}" no_link="{true}" account_id="{account_id}" content="{content}" utils="{utils}" item="{item}" each="{item in contents}" if="{includeInPicker(item)}" click_handler="{navigate}"></virtual> </div> </div> </div> </div> <div class="close" onclick="{close}"></div> </div> </div>', '', '', function(opts) {
var _this = this;

const { content, account_id, utils, _, riot, models, noty, user } = opts;
Object.assign(this, { content, account_id, utils, _, user });
window.addBlockSlider = this;

this.close = () => {
  //$('.ui.dimmer.updating').dimmer('show');
  _this.parent.addBlockOpen = null;
  $('.add-block-slider').removeClass('active');
  $('.pusher').removeClass('shrunk right');

  setTimeout(() => {
    _this.parent.update();
  }, 300);
};

this.defaultPath = () => {
  if (content.exists('documents/page-templates')) return 'documents/page-templates';
  if (content.exists('_views/blocks')) return '_views/blocks';
  return '_views';
};

if (!this.url) this.url = this.defaultPath();

this.on('mount', () => {
  _this.update();
});

this.on('update', () => {
  var link = '';
  var crumbs = [];
  var p = _this.url ? _this.url : '/';
  _this.contents = content.get(p);

  _this.url.split('/').forEach(function (crumb) {
    link += '/' + crumb;
    crumbs.push({
      link: link,
      text: crumb
    });
  });
  _this.crumbs = crumbs;
});

this.setUrl = url => {
  _this.url = url;
  _this.schema = content.resolveSchema(url);
  _this.update();
};

this.crumbNav = ev => {
  var el = ev.target;
  _this.setUrl($(el).data('link').replace(/^\//, ''));
};

this.includeInPicker = item => {
  return !content.isHidden(item._id) && (content.isView(item._id) || content.isFolder(item._id) || content.isJson(item._id) && Array.isArray(item.blocks) && item.blocks.length);
};

this.home = () => {
  _this.setUrl('');
};

this.blocks = () => {
  _this.setUrl("_views/blocks");
};

this.navigate = ev => {
  console.log('navigate', ev);

  var obj = ev.item.item;

  if (content.isFolder(obj._id)) _this.setUrl(obj._id);else {
    _this.addToDoc(obj);
    _this.close();
    _this.parent.update();
  }
};

this.addToDoc = item => {
  var doc = _this.parent.data;
  var currentBlock = doc.blocks.length;

  if (content.isView(item._id)) {

    // for adding pug templates
    doc.blocks.push({
      displayOrder: currentBlock,
      filename: item._id
    });

    _this.parent.update();
  } else if (item.blocks && item.blocks.length) {

    $('.ui.modal.addBlock').modal({
      observeChanges: true,
      onHidden: el => {
        // fixes bug where modals don't close
        $(".ui.dimmer.modals.page.transition.visible.active").removeClass('visible active').css('display', '');
      },
      onApprove: values => {

        _.each(_.cloneDeep(item.blocks), blk => {
          if (_this.parent.addAsSharedComponent) {
            doc.blocks.push({
              displayOrder: currentBlock,
              filename: blk.filename,
              dataPath: item._id
            });
          } else {
            blk.displayOrder = currentBlock;
            doc.blocks.push(blk);
          }
          currentBlock += 1;
        });
        _this.parent.update();
      }
    }).modal('show');
  }
};
});