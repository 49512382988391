import path from 'path'
import fs from 'fs'

import api from '../authenticated-request'
import mkdirp from './mkdirp'
import remove from './remove'
import readdirRecursive from './readdir-recursive'
import metaForFiles from './meta-for-files'
import metaForPath from './meta-for-path'
import _ from 'lodash'

export default async (opts = {}) => {
  
  const {account_id, file, prefix, shared, recursive, debug, firebase} = opts
  // prefix = a regex to match for which files to retrieve from the server
  // shared = an object containing shared fs references to create on all instances
  
  if (!account_id) {
    console.log('no account supplied to sync.js', opts)
    return
  }
  
  if (!firebase) {
    console.log('no firebase obj supplied to sync.js', opts)
    return
  }
  
  if (file) {
    return api({method: 'GET', url: `/data/${account_id}/${file}?includeMeta=true`, firebase: firebase},{}).then((result) => {
      if (result && result.response) updateLocalFile(result.response)
      return result.response
    })    
  }
  
  
  const localState = metaForPath({path: (prefix ? prefix : '/'), recursive: recursive } )
    
  if (debug) console.log(Object.keys(localState).length + " files found in local fs mirror.")
  
  var syncOptions = {localState: localState}
  if (prefix) syncOptions.prefix = `${prefix.replace(/\/+$/,'')}/`
  if (recursive) syncOptions.recursive = true

  return api({method: 'POST', url: `/fs/${account_id}/sync`, firebase: firebase}, syncOptions)
    .then((res) => { 
      
      const writeFiles = res.response.write
      const deleteFiles = res.response.delete
      
      if (typeof(writeFiles) == 'undefined' || typeof(deleteFiles) == 'undefined' ) {
        throw 'Sync failed due to response from server.'
      }
      
      if (debug) console.log('Sync Response: ', syncOptions, {write: writeFiles, delete: deleteFiles })
      
      for (var i = 0; i < deleteFiles.length; i++) deleteLocalFile(deleteFiles[i])
      
      //for (var i = 0; i < deleteFiles.length; i++) console.log(`Dry run delete ${deleteFiles[i]}`)
      
      for (var i = 0; i < writeFiles.length; i++) {
                                
        // because the fs global can get re-assigned to another indexeddb while this is running
        if (account_id != account_id) {
          if (debug) console.log(`Workspace changed to ${account_id}. Sync aborted for ${account_id}`)
          break;
        }
        
        const file = writeFiles[i]        
        updateLocalFile(file)
        
      }
      
      if (shared) updateSharedFs(shared)
        
      const changeCount = writeFiles.length + deleteFiles.length
        
      if (debug)  console.log(`Synced ${changeCount} files for ${account_id}.`, {writeFiles, deleteFiles})
      
      return changeCount
      
    }) 
    .catch((err) => {
      console.error(err)
    })
    
    function updateLocalFile(file) {
      var src;
      const fileName = file.meta.name
    
      try { src = JSON.parse(file.src)
      } catch(err) { src = file.src }

      if (debug) console.log(`Syncing: ${fileName}`)
      
      mkdirp(path.dirname(fileName))      
      if (typeof(src) == 'object') fs.writeFileSync(fileName, JSON.stringify(src, {}, '\t'))
      else fs.writeFileSync(fileName, src)
      
      // write metadata to .filename
      fs.writeFileSync(metaFileName(fileName), JSON.stringify(file.meta))
    }

    function deleteLocalFile(file) {
      try {
        if (!path.extname(file) || file.match(/_settings\.json$/)) { 
          const folderPath = '/' + file.replace(/_settings\.json$/,'')
          if (debug) console.log(`Deleting folder ${folderPath}`)
          remove(folderPath)
        }
        else {
          if (debug) console.log(`Deleting file ${file}`)
          fs.unlinkSync(file)
          fs.unlinkSync(metaFileName(file))
        }
        if (debug) console.log(`Deleted ${file}`)
        return true        
      } catch(err) {
        if (debug) console.log(`Error: deleting ${file}`, err)
        return false
      }
    }
    
    function metaFileName(fileName) { 
      return `${path.dirname(fileName)}/.${path.basename(fileName)}`
    }
          
    function updateSharedFs(shared){
      fs.mkdir('_schemas')
      fs.mkdir('_schemas/shared')
      fs.writeFile('_schemas/shared/account.json',JSON.stringify(shared.account) )
      fs.writeFile('_schemas/shared/tags.json', JSON.stringify(shared.tags) )
      fs.writeFile('_schemas/shared/folder.json', JSON.stringify(shared.folder) )  
      fs.writeFile('_schemas/shared/pug.json', JSON.stringify(shared.pug) )  
      fs.writeFile('_schemas/shared/json.json', JSON.stringify(shared.json) )  
      mkdirp('.foxflow-local')  
      fs.writeFileSync('.foxflow-local/initialized',new Date().toString())
    }
    
}