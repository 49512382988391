module.exports = (options, updateFn) => {
  
  var editor, editorDomId, doc, schema, account_id;
  
  (options && options.doc) ? doc = options.doc : doc = {};
  if (options && options.account_id) account_id = options.account_id;
  
  if (options && options.schema) schema = options.schema;
  if (options && options.element) editorDomId = options.element;
  else editorDomId = 'editor_holder'; 
  
  
  if (!editorDomId || !schema || !account_id) {
    console.log("Missing required editor option", account_id, editorDomId, schema)
    return false
  }
      
  if (typeof(JSONEditor.plugins) == 'undefined') JSONEditor.plugins = {};
  if (typeof(JSONEditor.plugins.selectize) == 'undefined') JSONEditor.plugins.selectize = {};
  
  if (typeof(JSONEditor.plugins.ace) !== 'undefined') JSONEditor.plugins.ace.theme = 'textmate';
  
  //JSONEditor.plugins.semantic = {enable: true};
  JSONEditor.plugins.selectize.enable = true;
  JSONEditor.plugins.selectize.remove_button = true;
  JSONEditor.plugins.selectize.multiArrayOnly = true;
  JSONEditor.defaults.options.ajax = true;
  JSONEditor.defaults.options.theme = 'semantic_ui';
  //JSONEditor.defaults.options.theme = 'html';
  JSONEditor.defaults.options.iconlib = 'fontawesome4';
  JSONEditor.defaults.options.display_required_only = false;
  JSONEditor.defaults.options.disable_collapse = true;
  JSONEditor.defaults.options.disable_edit_json = true;    
  JSONEditor.defaults.options.disable_array_delete_last_row = true;
  JSONEditor.defaults.options.disable_array_delete_all_rows = true;
  JSONEditor.defaults.options.disable_properties = false;
  JSONEditor.defaults.options.no_additional_properties = true;
  
  // only one custom line in this function below -- if (p == 'server') 
  JSONEditor.defaults.templates["default"] = function() {      
    return {
      compile: function(template) {
        
        var matches = template.match(/{{\s*([a-zA-Z0-9\-_ \.]+)\s*}}/g);
        var l = matches && matches.length;

        // Shortcut if the template contains no variables
        if(!l) return function() { return template; };

        // Pre-compute the search/replace functions
        // This drastically speeds up template execution
        var replacements = [];
        var get_replacement = function(i) {
          var p = matches[i].replace(/[{}]+/g,'').trim().split('.');
          
          var n = p.length;
          var func;
      
          if(n > 1) {
            var cur;
            func = function(vars) {
              cur = vars;
              for(i=0; i<n; i++) {
                cur = cur[p[i]];
                if(!cur) break;
              }
              return cur;
            };
          }
          else {
            p = p[0];
            func = function(vars) {
              // this is the custom line
              if (p == 'server') vars[p] = vars['self'] && account_id ? content.hostname : '';
              return vars[p];
            };
          }
      
          replacements.push({
            s: matches[i],
            r: func
          });
        };
        for(var i=0; i<l; i++) {
          get_replacement(i);
        }
        
        // The compiled function
        return function(vars) {
          var ret = template+"";
          var r;
          for(i=0; i<l; i++) {
            r = replacements[i];
            ret = ret.replace(r.s, r.r(vars));
          }
          return ret;
        };
      }
    };
  };
  
  // Specify upload handler
  JSONEditor.defaults.options.upload = function(type, file, cbs) {
    if (type === 'root.upload_fail') cbs.failure('Upload failed');
    // just a stub -- we handle file uploads client side
  };

  var refs = {};
  
  
  const loadRefs = (schema) => {
    var refUrls = content.getExternalRefs(schema);
    Object.keys(refUrls).forEach((refUrl) => {
      var s = content.getSchema(refUrl);
      refs[refUrl] = s;
      if (content.getExternalRefs(s)) loadRefs(s);
    });    
  }
  loadRefs(schema);
  
  
  var editorOptions = {
    refs: refs,
    schema: schema,
    startval: doc,
    show_errors: "always" // seems like this might be more reliable with "always"
  };
  if (schema && schema.options && schema.options.editor) editorOptions = Object.assign(editorOptions, schema.options.editor);
      
  editor = new JSONEditor(document.getElementById(editorDomId), editorOptions);
  
  editor.on('ready',function() {
    // Now the api methods will be available
    $(".ui.dropdown:not(.multiple)").dropdown();
    if ( options.onReady && typeof(options.onReady == "function") ) options.onReady();
  });

  // #### See above -- workaround re: disable_properties setting
  editor.on('ready',function() {
    $('.property-selector input:checkbox:not([checked])').click();
    $('.property-selector input:checkbox:not([checked])').click();
  });

  editor.on('change',function() {
    updateFn();
    $("[data-schematype='array'] > div:not(.tabbed-array) [data-schemapath] .ui.basic.segment").removeClass('basic');
    $('.ui.checkbox').checkbox();
    $('.field > select.ui.basic.selection.dropdown').dropdown();
  });

  return editor;
}