var fs = require('fs')
  , path = require('path')
  , readdirRecursive = require('./readdir-recursive')
  , frontSlash = require('./front-slash').default
  , noFrontSlash = require('./no-front-slash').default
  , metaForPath = require('./meta-for-path')
  , metaForFiles = require('./meta-for-files')
  ;
  
module.exports = function(inPath, opts = {}) {
  const {recursive, archived, includeSelf} = opts
  
  if (!inPath) inPath = '/'
  if (inPath.indexOf('/') != 0) inPath = `/${inPath}` // make paths without a "/" at the beginning work
  
  // if inPath is for a single file
  if (fs.existsSync(inPath) && path.extname(inPath)) {
    let contents = getContents(inPath, metaForFiles([inPath]))
    if (contents) return contents
  } else {
  // otherwise treat as directory request and return an array

    out = []
  
    const meta = metaForPath({path: inPath, recursive: recursive})
  
    Object.keys(meta).forEach( (file) => {
      
      let contents = getContents(file, meta)
      let isSelfRef = `/${file}` == (`${inPath == "/" ? '' : inPath}/_settings.json`)
      if (includeSelf || !isSelfRef) { // this avoids self-refs showing in dir listings, unless selfReferences is requested
        if (contents && !(isArchived(contents) && !archived) ) out.push( contents )
      }
    })

    return out;

    
  }
  
  function isArchived(obj) {
    if (typeof obj != "object") return false
    if (!obj.tags) return false
    //console.log(obj._id, obj.tags && Array.isArray(obj.tags) && obj.tags.includes('Archived') )
    if (obj.tags && Array.isArray(obj.tags) && obj.tags.includes('Archived') ) return true
  }
  
  function getContents(file, meta) {
    
    const ext = path.extname(file)
    const basename = path.basename(file)
    if (ext == ".json") {
      let contents
      try { contents = fs.readFileSync(file).toString() } catch(err) {console.log(err) }    
    
      // _id of xyz/_settings.json moves to xyz,  _id of _settings.json and anything else as itself 
      const name = (basename == '_settings.json') ? file.replace(/\/?_settings\.json$/,'') : file
      if (contents && ext == ".json") { 
        return Object.assign(JSON.parse(contents), {_basename: path.basename(name), "_id": name ? name : '/', "_metadata": meta[file]})
      }        
        
    } else {
      
      // might need to add logic here for dirs that don't have _settings.json
      
      var out = { _id: file,  _basename: basename, type: 'file', ext: path.extname(file), _metadata: meta[noFrontSlash(file)]}
      if (['.gif', '.jpg', '.jpeg', '.png', '.svg', '.bmp'].includes(ext)) out.src = out._metadata.mediaLink
      return out      
        
    }
  }
  
}