var fs = require('fs')
  , p = require('path')
  , omit = require('lodash').omit
  ;
  
module.exports = (files = []) => {
  var meta = {}
  //console.log('Getting meta for files', files)
  files.forEach((file) => {
    if (file && !file.match(/^_schemas\/shared/) && p.extname(file) && !p.basename(file).match(/^\./)) {
      //!file.match(/^_schemas\/shared/) &&
      const metaFileName = `${p.dirname(file)}/.${p.basename(file)}`
      let fileMeta
      if (fs.existsSync(metaFileName))
        try { fileMeta = fs.readFileSync(metaFileName).toString() } catch(err) { console.log(err)}
      
      const name = (file.indexOf('/') == 0 ? file.slice(1) : file)
      if (fileMeta) meta[name] = omit(JSON.parse(fileMeta), ['name'])
      else meta[name] = {}
      
    }
  });
  
  //console.log('Got meta', meta)

  return meta;
}