var riot = require('riot');
module.exports = 
riot.tag2('data-form', '<div if="{settings}"> <virtual if="{!settings.fieldSets &amp;&amp; settings.fields}"> <div class="field {field.name}" data-is="field" each="{field, idx in settings.fields}" field="{field}"></div> </virtual> <virtual if="{settings.fieldSets &amp;&amp; settings.fields}"> <virtual each="{fieldset, header in settings.fieldSets}"> <div class="ui top attached inverted header">{header}</div> <div class="ui bottom attached padded segment"> <div class="field" data-is="field" each="{field in loadFields(fieldset)}" field="{field}"></div> </div> </virtual> </virtual> <div class="ui error message"> </div> </div>', '', '', function(opts) {
var _this = this;

this.on('mount', function () {
  this.settings = opts.settings;
  this.update();
});

this.on('updated', function () {
  $('select').each(function (el) {
    var value = $(el).attr('value');
    if (value) $(el).val(value);
  });
  $('select').dropdown({});
  $('.field.id').addClass('disabled');
});

this.loadFields = fieldset => {
  return _.map(fieldset, name => {
    return _.find(_this.settings.fields, { name: name });
  });
};
});