var riot = require('riot');
module.exports = 
riot.tag2('form-modal', '<virtual if="{opts.settings &amp;&amp; opts.settings.modal}"><i class="close icon"></i> <div class="header" if="{opts.settings.modal.title}">{opts.settings.modal.title}</div> <div class="content {\'no-title\' : !opts.settings.modal.title}"> <p if="{opts.settings.modal.description}"> {opts.settings.modal.description}</p> <form class="ui form basic segment"> <div class="modal-data-form" data-is="data-form" settings="{opts.settings}"></div> </form> </div> <div class="actions"> <div class="ui button deny" if="{!opts.settings.noCancel}">{opts.settings.modal.denyText ? opts.settings.modal.denyText : \'Cancel\'}</div> <div class="ui primary approve submit button">{opts.settings.modal.approveText ? opts.settings.modal.approveText : \'Save\'}</div> </div> </virtual>', '', '', function(opts) {
var tag = window.formModal = this;

this.on('mount', () => {
  if (typeof opts.settings == "object") {
    var $form = $('#form-modal .ui.form');

    $('#form-modal').modal({
      observeChanges: true,
      onHidden: el => {
        // fixes bug where modals don't close
        $(".ui.dimmer.modals.page.transition.visible.active").removeClass('visible active').css('display', '');
        $('#form-modal').html('').attr('data-is', 'form-modal').attr('style', '');
      },
      onVisible: opts.settings.onReady && typeof opts.settings.onReady == "function" ? opts.settings.onReady : () => {
        return true;
      },
      onApprove: () => {

        $('#form-modal ui.error.message').html('');

        if ($form && $form.length) $form.form({
          fields: opts.settings.validates ? opts.settings.validates : [],
          inline: true
        });

        if (!$form.form('is valid')) return false;
        // form is invalid
        else {
            // form valid, proceed to callback or by default, return true
            if (opts.settings && opts.settings.modal && typeof opts.settings.modal.onApprove == "function") {
              var values = $form.form('get values');

              return opts.settings.modal.onApprove(values);
            } else return true;
          }
      },
      onDeny: opts.settings.modal && typeof opts.settings.modal.onDeny == "function" ? opts.settings.modal.onDeny : () => {
        return true;
      }
    }).modal('show');
  }
});
});